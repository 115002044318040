import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import {getDate, getMonth, getDateAndMonth} from '../utils/date-utils'

import Slider from "react-slick"
import carousel_img1 from '../images/carousel/carousel_img1.jpg'
import carousel_img2 from '../images/carousel/carousel_img2.jpg'
import carousel_img3 from '../images/carousel/carousel_img3.jpg'

import News from '../components/News'


export default class IndexPage extends React.Component {

  render() {
    const { data, location } = this.props
    const { edges: latestPosts } = data.latestPosts
    const { edges: latestNews } = data.latestNews

    const settings = {
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    }

    return (
      <Layout location={location}>

        <div id="top-banner" className="container-fluid carousel-container horizontal-section-container clearfix">
          <div className="row" style={{ lineHeight: 0 }}>
            <div className="col-xs-12">

              <div id="top-banner-carousel" className="owl-carousel owl-theme full-page-image-slider">
                <Slider {...settings}>

                  <div className="item">
                    <div className="text bottom left">
                      <Link to="/about/"><h2>Votre Église</h2></Link>
                      <div className="clearfix"></div>
                      <Link to="/about/"><h5>À Cagnes-sur-Mer</h5></Link>
                    </div>
                    <Link to="/about/"><img className="lazyOwl" src={carousel_img1} alt="Église Goshen" /></Link>
                  </div>

                  <div className="item">
                    <div className="text bottom right">
                      <Link to="/about/"><h2>Goshen</h2></Link>
                      <div className="clearfix"></div>
                      <Link to="/about/"><h5>Une assemblée évangélique familiale</h5></Link>
                    </div>
                    <Link to="/about/"><img className="lazyOwl" src={carousel_img2} alt="Église Goshen" /></Link>
                  </div>

                  <div className="item">
                    <div className="text bottom left no-side-padding">
                      <Link to="/about/"><h2>Votre Église</h2></Link>
                      <div className="clearfix"></div>
                      <Link to="/about/"><h5>À Cagnes-sur-Mer</h5></Link>
                    </div>
                    <Link to="/about/"><img className="lazyOwl" src={carousel_img3} alt="Église Goshen" /></Link>
                  </div>
                </Slider>
              </div>

            </div>
          </div>
        </div>

        {/* News */}
        {/*<News
          title={"Fête de la Musique avec des louanges"}
          imageName={'reveils-et-louange-accueil'}
          content={<span>Rejoignez-nous pour une soirée musicale gratuite <i>Réveils et louange</i>, animée par Daniel Boudrias, évangéliste du Québec</span>}
          linkTo={'/news/2024-05-27-daniel-boudrias/'}
        />*/}

        <div className="container-fluid horizontal-section-container clearfix">
          <div className="row">

            <div className="col-sm-5">
              <div id="home-about-us" className="section-container clearfix">

                <Link to="/about/" className="section-header with-icon">
                  <div className="icon"><span className="glyphicon glyphicon-info-sign"></span></div>
                  <h5 className="text">À Propos de Nous</h5>
                </Link>
                <p>Goshen est une assemblée évangélique familiale, membre de la Fédération des Eglises Chrétiennes Evangéliques. Nous sommes profondément attachés à la Parole vivante de Dieu et désirons voir Jésus glorifié dans nos vies, au sein de l’Eglise et dans notre pays de France.</p>
                <p><Link to="/about/" className="btn btn-outline-inverse btn-md">en savoir plus</Link></p>
              </div>
            </div>

            <div className="col-sm-7">
              {/* Latest News */}
              <div id="home-latest-news" className="section-container clearfix">

                <Link to="/index-news/" className="section-header with-icon">
                  <div className="icon"><span className="glyphicon glyphicon-bullhorn"></span></div>
                  <h5 className="text">Dernières Nouvelles</h5>
                </Link>

                <div className="section-content">

                  <div className="thumbnail-list row equal-height clearfix">

                    {latestNews
                      .map(({ node: post }) => (
                        <div className="item col-sm-6" key={post.id}>
                          <div className="thumbnail item-content">
                            <Link to={post.fields.slug}>
                              <Img fluid={post.frontmatter.cover_image_home.childImageSharp.fluid}                                  
                                   />
                            </Link>
                            <div className="caption">
                              <span className="date">{getDateAndMonth(post.frontmatter.date)}</span>
                              <h6 className="title">
                                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}

                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>

        <div className="container-fluid horizontal-section-container clearfix">
          <div className="row">

            <div className="col-sm-12">
              {/* Latest Posts */}
              <div id="home-latest-posts" className="section-container clearfix">

                <Link to="/index-blog/" className="section-header with-icon">
                  <div className="icon"><span className="fa fa-pencil"></span></div>
                  <h5 className="text">Derniers Billets de Blog</h5>
                </Link>

                <div className="section-content">

                  <div className="horizontal-news-list row equal-height clearfix">

                    {latestPosts
                      .map(({ node: post }) => (
                        <div className="item col-sm-3" key={post.id}>
                          <div className="item-content clearfix">
                            <div className="date">
                              <div className="day">{getDate(post.frontmatter.date)}</div>
                              <div className="month">{getMonth(post.frontmatter.date)}</div>
                            </div>
                            <div className="text">
                              <h6 className="title">
                                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                              </h6>
                              <p>{post.excerpt}</p>
                            </div>
                          </div>
                        </div>
                      ))}

                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>

      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latestPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date
          }
        }
      }
    },
    latestNews: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "news-post" } }},
      limit: 2
    ) {
      edges {
        node {          
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cover_image_home {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
        }
      }
    }
  }
`